import axios from 'axios';
import { getUrlParam } from '@/utils';
import { channelCode } from '@/js/channel';

// process.env.VUE_APP_VIDEO1_API_URL;
const instance = axios.create({
	baseURL: process.env.VUE_APP_VIDEO3_API_URL,
	timeout: 20000
});

// 添加请求拦截器
instance.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		const channel = channelCode;

		// 从env配置文件中获取-jekins构建后也无法获取呢？
		// const channel = process.env.VUE_APP_CHANNEL_CODE;
		// console.log('channel',channel);

		// 在这里通过本地存储或状态管理获取 token
		const UserLoginInfoStorage = sessionStorage.getItem('UserLoginInfoStorage');

		// 如果存在 token,在请求头中携带
		if (UserLoginInfoStorage != null) {
			//console.log('UserLoginInfoStorage', JSON.parse(UserLoginInfoStorage));
			let userinfo = JSON.parse(UserLoginInfoStorage);
			//console.log('token', userinfo.token);
			// config.headers.Authorization = `Bearer ${userinfo.token}`;
			config.headers.Authorization = `${userinfo.token}`;
		}

		config.params = {
			channel,
			...config.params
		};
		return config;
	},
	function (error) {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
instance.interceptors.response.use(
	function (response) {
		// 对响应数据做点什么
		// console.log('response', response);

		if (response.data.code == 401) {
			// 返回 401 清除token信息并跳转到登录页面
			sessionStorage.removeItem('UserLoginInfoStorage');
			// 动态获取 Vue Router 实例
			const router = axios.defaults.router;
			console.log('router', router);
			router.replace({
				path: '/register',
				query: { onpage: 'login' }
			});
		}
		return response.data;
	},
	function (error) {
		console.log('error', error);
		// 对响应错误做点什么
		return Promise.reject(error);
	}
);

export default instance;
