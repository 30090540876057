import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6c43089a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "search-div"
};
const _hoisted_2 = {
  class: "search-input"
};
import { ref } from 'vue';
export default {
  __name: 'SearchInput',
  emits: ['search', 'cancel'],
  setup(__props, {
    expose: __expose,
    emit: emits
  }) {
    const keysValue = ref('');
    const search = () => {
      if (keysValue.value) {
        emits('search', keysValue.value);
      }
    };
    const update = value => {
      if (!value) {
        emits('cancel');
      }
    };
    const setKeyWord = value => {
      keysValue.value = value;
    };
    __expose({
      setKeyWord
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_search = _resolveComponent("van-search");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_icon, {
        class: "icon",
        name: "arrow-left",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1))
      }), _createVNode(_component_van_search, {
        modelValue: keysValue.value,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => keysValue.value = $event), update],
        placeholder: "输入关键词"
      }, {
        "right-icon": _withCtx(() => []),
        _: 1
      }, 8, ["modelValue"]), _createElementVNode("div", {
        class: "search-button",
        onClick: search
      }, "搜索")])]);
    };
  }
};