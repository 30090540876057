import request from './request';

export default {
	// 查询首页视频
	queryHomeVideo: (params) => {
		return request({
			url: '/api/video/home',
			params
		});
	},

	// 查询视频分类列表
	queryClassificationList: (params) => {
		return request({
			url: '/api/video/nav',
			params
		});
	},

	// 查询视频分类列表
	queryVideoClassificationList: (params) => {
		return request({
			url: '/api/video/getList',
			params
		});
	},

	// 查询视频详情
	queryVideoDetails: (params) => {
		return request({
			url: '/api/video/detail',
			params
		});
	},

	// 查询公告
	queryAnnouncement: (params) => {
		return request({
			url: '/api/index/notice',
			params
		});
	},

	// 查询广告图
	// type =1 表示banner图  =2 表示首页广告  =3 表示详情页广告
	queryAd: (params) => {
		return request({
			url: '/api/index/images',
			params
		});
	},

	// 查询所有广告图
	getAllAd: (params) => {
		return request({
			url: '/api/index/getAllAd',
			params
		});
	},

	// 查询网站配置
	queryWebsiteConfiguration: (params) => {
		return request({
			url: '/api/index/config',
			params
		});
	},

	// 查询推荐列表
	queryRecommendedList: (params) => {
		return request({
			url: '/api/video/getRelateList',
			params
		});
	},

	// 搜索
	search: (params) => {
		return request({
			url: '/api/video/search',
			params
		});
	},

	// 查询分类列表
	getCateList: (params) => {
		return request({
			url: 'api/video/getCateList',
			params: {
				...params
			}
		});
	},

	// 上报广告
	// platform  类型  ios    android   h5
	// imei   手机imei号
	// device 设备号
	// channel 渠道
	// ad_ids  广告id   多个用,  分开  比如1,2,3
	// report_type   0浏览 1 点击
	reportAds: (params) => {
		return request({
			url: 'api/report/ads',
			params: {
				platform: 'h5',
				imei: localStorage.getItem('browserId'),
				device: localStorage.getItem('browserId'),
				report_type: 0,
				...params
			}
		});
	},

	// 安装上报
	reportInstall: (params) => {
		return request({
			url: '/api/report/install',
			params: {
				platform: 'h5',
				//imei: localStorage.getItem('browserId'),
				//device: localStorage.getItem('browserId'),
				...params
			}
		});
	},
	// 上报IP,统计接口
	reportIp: (params) => {
		return request({
			url: '/api/report/ip',
			params: {
				// platform: params.phone_brand ?? 'h5',
				// imei: localStorage.getItem('browserId'),
				// device: localStorage.getItem('browserId'),
				platform: 'h5',
				...params
			}
		});
	},

	// 会员注册
	userRegister: (params) => {
		return request({
			method: 'post',
			url: '/api/user/register',
			params: {
				platform: 'h5',
				...params
			}
		});
	},

	// 会员登录
	userLogin: (params) => {
		return request({
			method: 'post',
			url: '/api/user/login',
			params: {
				platform: 'h5',
				...params
			}
		});
	},

	// 查询 任务中心
	getTakList: (params) => {
		return request({
			url: '/api/user/task/getTakList',
			params
		});
	},

	// 查询 签到列表
	getSignList: (params) => {
		return request({
			url: '/api/user/task/signList',
			params
		});
	},

	// 会员签到
	userSign: (params) => {
		return request({
			method: 'post',
			url: '/api/user/task/sign',
			params: {
				platform: 'h5',
				...params
			}
		});
	}

	// 获取配置
	//    getWebconfig: (params) => {
	//     return request({
	//       method: "get",
	//       url: "/api/index/config",
	//       params: {
	//         platform: "h5",
	//         ...params,
	//       },
	//     });
	//   },
};
