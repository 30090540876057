import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import { onMounted } from 'vue';
import { watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { nextTick, inject } from 'vue';
import { useIndexStore } from '../store';
import { storeToRefs } from 'pinia';
export default {
  __name: 'CategoryNav',
  props: {
    list: {}
  },
  emits: ['query'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const userIndex = useIndexStore();
    const {
      categoryList
    } = storeToRefs(userIndex);
    const {
      fetchHomepageVideoList,
      fetchVideoList
    } = userIndex;
    const route = useRoute();
    const router = useRouter();
    const active = ref();
    const tabs = ref();
    const resetPage = inject('resetPage', () => {});

    // 分类切换
    const change = ({
      name
    }) => {
      const item = categoryList.value[name];
      if (item.url) {
        // 有url 跳转
        return window.location.href = item.url;
      }

      // if (name === 0) {
      // 首页
      // fetchHomepageVideoList()
      // router.push('/home')
      // }
      // else if (name === 1) {
      //   fetchVideoList({
      //     id: item.id,
      //     page: 1,
      //     type: item.type,
      //     only_topic: item.only_topic,
      //   })
      //   // 热门
      //   router.push({
      //     path: 'home',
      //     query: {
      //       title: '热门',
      //       id: 'hot',
      //     }
      //   })
      // }
      // else {
      // fetchVideoList({
      //   id: item.id,
      //   page: 1,
      //   type: item.type,
      //   only_topic: item.only_topic,
      // })
      // 首页其它分类
      router.push({
        path: 'home',
        query: {
          title: item.title,
          onlytTopic: item.only_topic,
          type: item.type,
          id: item.id
        }
      });
      // }
    };

    const changeTabs = () => {
      active.value = categoryList.value.findIndex(v => v.title === route.query.title);
      nextTick(() => {
        setTimeout(() => {
          tabs.value?.resize();
        }, 1000);
      });
    };
    watch(() => categoryList.value, () => {
      if (route.path === '/home') {
        changeTabs();
      }
    });
    watch(route, v => {
      if (route.path === '/home') {
        if ([v.query.title, v.query.classify, v.query.hot].every(v => !v) && route.path === '/home') {
          changeTabs();
        }
      }
    });
    onMounted(() => {
      if (route.path === '/home') {
        changeTabs();
      }
    });
    return (_ctx, _cache) => {
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createBlock(_component_van_tabs, {
        class: "tabs",
        sticky: "",
        ref_key: "tabs",
        ref: tabs,
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        "line-height": "0",
        "line-width": "0",
        onClickTab: change
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categoryList), (item, index) => {
          return _openBlock(), _createBlock(_component_van_tab, {
            name: index,
            key: index
          }, {
            title: _withCtx(() => [false ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass({
                addColor: item.only_topic === '1' && true
              })
            }, _toDisplayString(item.title), 3)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(item.title), 1)]),
            _: 2
          }, 1032, ["name"]);
        }), 128))]),
        _: 1
      }, 8, ["active"]);
    };
  }
};